<template>
    <div
        v-if="dialog"
        class="general-info-modal"
        @click.self="close()"
    >
        <div class="general-info-modal__dialog">
            <header class="general-info-modal__header">
                <h2>{{ title }}</h2>
                <span
                    class="general-info-modal__close"
                    @click="close()"
                />
            </header>
            <section class="general-info-modal__content">
                <ul
                    class="general-info-modal__selected-list"
                >
                    <li
                        v-for="(item, index) in items"
                        :key="index"
                        class="general-info-modal__selected-item"
                        :class="{'selected': selected.some((value) => value === item)}"
                        @click="selectItem(item)"
                    >
                        {{ item }}
                    </li>
                </ul>
            </section>
            <footer>
                <div class="general-info-modal__two-button-block">
                    <button
                        class="general-info-modal__two-button general-info-modal__two-button--gray"
                        @click.prevent="close()"
                    >
                        cancel
                    </button>
                    <button
                        class="general-info-modal__two-button general-info-modal__two-button--main"
                        @click.prevent="submit()"
                    >
                        ok
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GeneralInfoSelectModal',
    props: {
        items: {
            type: Array,
            required: true
        },
        selectedItems: {
            type: Array
        },
        title: {
            type: String,
            required: true
        }
    },
    data: () => ({
        dialog: false,
        selected: []
    }),
    watch: {
        selectedItems: {
            handler(value) {
                const res = []
                value.forEach((element) => {
                    if (this.items.some((item) => item === element)) {
                        res.push(element)
                    }
                });
                this.selected = [
                    ...res
                ]
            },
            deep: true
        }
    },
    methods: {
        open() {
            this.dialog = true
        },
        submit() {
            this.$emit('confirm', [...this.selected])
            this.close()
        },
        close() {
            this.$emit('cancel')
            this.dialog = false
            this.selected = []
        },
        selectItem(item) {
            if (this.selected.some((element) => element === item)) {
                this.selected = this.selected.filter((element) => element !== item)
            } else {
                this.selected.push(item)
            }
        }
    }
}
</script>

<style lang="scss">
.general-info-modal {
    position: fixed;
    overflow: hidden;
    background-color: rgba(11, 19, 31, .45);
    backdrop-filter: blur(3px);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    overscroll-behavior: contain;
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    &__dialog {
        max-width: 800px;
        width: 100%;
        background-color: #fff;
        -webkit-box-shadow: 0 10px 40px rgba(25, 50, 71, .2);
        box-shadow: 0 10px 40px rgba(25, 50, 71, .2);
        border-radius: $input-border-radius;
        top: 90px;
        left: calc(50% - 250px);
        z-index: 999999999;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__header {
        font-weight: 500;
        color: $font-color-main;
        font-size: 24px;
        border-bottom: 1px solid $border-light;
        padding: 20px;
        position: relative;
    }
    &__close {
        position: absolute;
        right: 20px;
        top: 50%;
        min-width: 14px;
        width: 14px;
        height: 14px;
        background: url("~@/assets/img/svg/close.svg") no-repeat;
        background-size: contain;
        transform: translateY(-50%);
        color: #4F4F4F;
        cursor: pointer;
    }
    &__content {
        padding: 20px 20px 0;
        color: #757c89;
        font-size: 16px;
        line-height: 1.3;
    }
    &__two-button-block {
        display: flex;
        justify-content: space-between;
        padding: 20px;
    }
    &__one-button-block {
        display: flex;
        justify-content: center;
        padding: 20px;
        button {
            text-transform: capitalize;
        }
    }
    &__two-button {
        padding: 15px 0;
        width: calc(50% - 10px);
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: $input-border-radius;
        cursor: pointer;
        &--gray {
            background-color: #f5f5f5;
            &:hover {
                background-color: rgba(145, 149, 154, 0.2);
                transition: 0.5s;
            }
        }
        &--main {
            background: $main-color;
            color: white;
        }
    }
    &__one-button {
        padding: 20px 0;
        width: 80%;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: $input-border-radius;
        cursor: pointer;
        &--main {
            background: $main-color;
            color: white;
        }
    }
    &__selected-list {
        margin: 10px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
    }

    &__selected-item {
        position: relative;
        display: flex;
        background-color: #f4f4f4;
        padding: 10px 15px;
        border-radius: 50px;
        user-select: none;
        cursor: pointer;
        &.selected {
            background-color: rgba($color: $main-color, $alpha: .2);
        }
    }
}
@media (max-width: 480px) {
    .general-info-modal {
        & .general-info-modal__content {
            padding: 15px 15px 0;
        }
        & .general-info-modal__two-button-block {
            padding: 15px;
        }
    }
}
</style>
