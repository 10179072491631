<template>
    <div
        class="form-select-general-info"
    >
        <fieldset
            class="form-select-general-info__fieldset"
            :class="{ 'error-border' : validationError }"
        >
            <legend
                class="form-select-general-info__legend"
                :class="{ 'error-font-color': validationError }"
            >
                {{ label }}
            </legend>
            <div class="form-select-general-info__selected-block">
                <ul
                    class="form-select-general-info__selected-list"
                >
                    <li
                        v-for="(item, index) in itemsSelected"
                        :key="index"
                        class="form-select-general-info__selected-item"
                    >
                        <span>
                            <span class="form-select-general-info__name">
                                {{ item }}
                            </span>
                        </span>
                    </li>
                </ul>
                <span
                    v-if="!itemsSelected.length"
                    class="form-select-general-info__placeholder"
                >
                    Select {{ label.toLowerCase() }}
                </span>
                <custom-button
                    small
                    hover
                    :classes="'btn-pencil'"
                    :width="'50px'"
                    :height="'28px'"
                    @on-btn-click="edit"
                >
                    <pencil size="18" />
                    Edit
                </custom-button>
            </div>
            <div
                v-if="validationError"
                class="form-input__error"
            >
                {{ validationError }}
            </div>
        </fieldset>
    </div>
</template>

<script>
import Pencil from 'mdi-vue/Pencil'

export default {
    name: 'FormSelectGeneralInfo',
    components: {
        Pencil
    },
    props: {
        itemsSelected: {
            type: Array
        },
        errors: {
            type: String
        },
        label: { type: String }
    },
    computed: {
        validationError() {
            return this.errors ? this.errors : false;
        }
    },
    methods: {
        edit(e) {
            e.preventDefault()
            this.$emit('edit')
        }
    }
}
</script>

<style lang="scss">
.form-select-general-info {
    outline: none;
    border-radius: 4px;
    min-height: 50px;

    &__icon {
        position: absolute;
        color: rgba(0,0,0,.54);

        &--prepend{
            top: 12px;
            left: 24px;
        }

        &--append {
            top: 10px;
            right: 12px;
            z-index: 10;
        }
    }

    &__fieldset {
        position: relative;
        outline: none;
        display: flex;
        border-radius: $input-border-radius;
        border: $input-border;
        min-height: 48px;

        &.active {
            border: $input-border-success;
        }
        &.error-border {
            border: 1px solid #ff5252;
        }
    }

    &__legend {
        display: table;
        color: #b3bbc6;
        caret-color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -8px;
        background-color: white;
        border: none;
    }

    &__input {
        box-sizing: border-box;
        display: inline-block;
        //padding-left: 75px;
        min-width: 100%;
        cursor: pointer;
        border: none;
        border-radius: 0;
        margin-left: 0;
        outline: none;
        font-family: $font-family-base, sans-serif;
        min-height: 46px;
        caret-color: #b3bbc6;
        border-bottom: $input-border;
        user-select: text !important;
        font-size: 14px;
        padding-left: 70px!important;
    }

    &__placeholder {
        color: rgba(0, 0, 0, .38);
    }

    &__selected-block {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 15px;
        margin-right: 25px;
        height: 100%;
        min-height: 48px;
        gap: 10px;
        @media (max-width: 480px) {
            margin-right: 10px;
        }
    }

    &__selected-list {
        margin: 10px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
    }

    &__selected-item {
        position: relative;
        display: flex;
        background-color: #f4f4f4;
        padding: 10px 15px;
        border-radius: 50px;
    }
}

.btn-base.small-btn.btn-pencil {
    border: none;
    font-size: 14px;
    &:hover {
        color: $main-color;
        svg {
            color: $main-color;
        }
    }
    svg {
        margin-right: 10px;
        color: $font-color-main;
        transition: .2s;
    }
}

@media screen and (max-width : 768px){

    .form-select-general-info {

        &__input {
            padding-left: 0;
        }
    }
}
</style>
