<template>
    <form
        class="wizard-general__form wizard__form"
        @submit.prevent="validate"
    >
        <div
            v-if="loading"
            class="radius-loader--wrapper"
        >
            <div
                class="background-for-radius-loader"
            >
                <img
                    src="@/assets/img/svg/animation/LoaderRadius.svg"
                    alt="loader"
                >
            </div>
        </div>
        <template
            v-for="field in fieldsData"
            v-else
        >
            <div
                :key="field.short_name"
                class="wizard-general__field"
            >
                <div class="wizard-general__info">
                    <h3 class="wizard-general__info-title">
                        {{ field.long_name }}
                        <div
                            v-if="!!field.is_public"
                            class="wizard-general__info-label-public tooltip-zoom"
                            data-tooltip-attribute="This information will be visible to customers."
                        >
                            PUBLIC
                        </div>
                    </h3>
                    <p class="wizard-general__info-description">
                        {{ field.description }}
                    </p>
                </div>
                <div class="wizard-general__info-field">
                    <form-textarea
                        v-if="field.type === 'text'"
                        v-model="formFields[field.short_name]"
                        :legend="field.long_name"
                        :field.sync="formFields[field.short_name]"
                        :errors="validationMessage($v.formFields[field.short_name])"
                        :is-valid="$v.formFields[field.short_name].$dirty && !$v.formFields[field.short_name].$anyError"
                        @input.native="$v.formFields[field.short_name].$touch()"
                        @blur="onBlur(field)"
                        @focus="onFocus(field)"
                    />
                    <form-input
                        v-else-if="field.type === 'string'"
                        v-model="formFields[field.short_name]"
                        :legend="field.long_name"
                        :field.sync="formFields[field.short_name]"
                        :errors="validationMessage($v.formFields[field.short_name])"
                        :is-valid="$v.formFields[field.short_name].$dirty && !$v.formFields[field.short_name].$anyError"
                        @input.native="$v.formFields[field.short_name].$touch()"
                        @blur="onBlur(field)"
                        @focus="onFocus(field)"
                    />
                    <work-samples
                        v-else-if="field.short_name === 'work_samples'"
                        :files="[...formFields[field.short_name]]"
                        :label="field.long_name"
                        :errors="validationMessage($v.formFields[field.short_name])"
                        @delete="deleteFile"
                        @upload="uploadFiles"
                    />
                    <form-select-general-info
                        v-else
                        :items-selected="[...formFields[field.short_name]]"
                        :label="field.long_name"
                        :errors="validationMessage($v.formFields[field.short_name])"
                        :is-valid="$v.formFields[field.short_name].$dirty && !$v.formFields[field.short_name].$anyError"
                        @input.native="$v.formFields[field.short_name].$touch()"
                        @blur="onBlur(field)"
                        @focus="onFocus(field)"
                        @edit="openModal(field)"
                    />
                    <span
                        v-if="field.isSaved"
                        class="wizard-general__info-label-saved"
                    >
                        <check
                            width="18"
                        />
                        Saved
                    </span>
                </div>
            </div>
        </template>
        <slot name="buttons" />
        <general-info-select-modal
            ref="select-modal"
            :items="modal.items"
            :selected-items="modal.selectedItems"
            :title="modal.title"
            @confirm="onConfirmModal"
            @cancel="clearModal"
        />
    </form>
</template>

<script>
import Api from '@/helpers/api/index.js'
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '@/helpers/event-bus';
import { validationMixin } from 'vuelidate'
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationMessage } from 'vuelidate-messages'
import {
    required,
    minLength
} from 'vuelidate/lib/validators';
import { formMessages } from '@/validation/wizard/wizard-general/Messages'

import {
    ACTION_UPDATE_GENERAL_INFO
} from '@/store/modules/writer/action-types'
import FormSelectGeneralInfo from '@/components/common/form-elements/FormSelectGeneralInfo.vue'
import GeneralInfoSelectModal from '@/components/modals/GeneralInfoSelectModal.vue'
import Check from 'mdi-vue/CheckCircleOutline.vue'
import WorkSamples from '@/components/WorkSamples.vue';

const { mapActions: mapWriterActions } = createNamespacedHelpers('writer')

export default {
    name: 'Index',
    components: {
        FormSelectGeneralInfo,
        GeneralInfoSelectModal,
        WorkSamples,
        Check
    },
    metaInfo: {
        title: 'General Info'
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    props: {
        successCallback: {
            type: Function,
            required: false
        }
    },
    validations() {
        const rules = {
            formFields: {}
        }
        this.fieldsData.forEach(({ short_name, type }) => {
            this.$set(rules.formFields, short_name, {
                required,
                minLength: type === 'text' ? minLength(50) : minLength(1)
            })
        })
        return rules
    },
    data() {
        return {
            showModal: false,
            formFields: {},
            fieldsData: [],
            loading: true,
            modal: {
                title: '',
                items: [],
                selectedItems: [],
                key: ''
            }
        }
    },
    async mounted() {
        await this.getData()
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapWriterActions([
            ACTION_UPDATE_GENERAL_INFO
        ]),
        async confirm() {
            const payload = {}
            Object.entries(this.formFields).forEach(([key, value]) => {
                if (value.length > 0) {
                    payload[key] = value
                }
            })
            await this.updateForm(payload)
        },
        async getFields() {
            const { data } = await Api.get('/api/writer/application/get-fields')
            const workSamples = data.find(({ short_name }) => short_name === 'work_samples')
            this.fieldsData = [
                ...data.map((item) => ({
                    ...item,
                    isSaved: false
                }))
                    .filter(({ short_name }) => short_name !== 'work_samples'),
                workSamples
            ]
            data.forEach(({ short_name, type }) => {
                this.$set(this.formFields, short_name, type === 'array' ? [] : '')
            })
        },
        async getWriterApplications() {
            const { data } = await Api.get('/api/writer/application/get')
            Object.keys(this.formFields).forEach((key) => {
                this.formFields[key] = data[key] ?? this.formFields[key]
            })
        },
        openModal(field) {
            this.modal = {
                items: [...field.possible_values],
                title: field.long_name,
                key: field.short_name,
                selectedItems: [...this.formFields[field.short_name]]
            }
            this.onFocus(field)
            this.$refs['select-modal'].open()
        },
        async onConfirmModal(items) {
            this.formFields[this.modal.key] = [...items]
            await this.onBlur({ short_name: this.modal.key, long_name: this.modal.title })
            this.clearModal()
        },
        clearModal() {
            this.modal = {
                items: [],
                title: '',
                key: '',
                selectedItems: []
            }
        },
        submit() {
            this.successCallback()
        },
        onFocus({ short_name }) {
            const fieldIndex = this.fieldsData.findIndex((filed) => filed.short_name === short_name)
            this.fieldsData[fieldIndex].isSaved = false
        },
        async onBlur({ short_name, long_name }) {
            this.$v.formFields[short_name].$touch()
            if (this.$v.formFields[short_name].$dirty && !this.$v.formFields[short_name].$anyError) {
                await this.updateForm({ [short_name]: this.formFields[short_name] }, long_name)
                const fieldIndex = this.fieldsData.findIndex((filed) => filed.short_name === short_name)
                this.fieldsData[fieldIndex].isSaved = true
            }
        },
        async updateForm(payload, field = 'general information') {
            try {
                await this.ACTION_UPDATE_GENERAL_INFO(payload)
                eventBus.$emit('showSnackBar', `Your ${field.toLowerCase()} has been changed`, 'success');
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'error');
                console.error(err)
            }
        },
        deleteFile(id) {
            const index = this.formFields['work_samples'].findIndex(({ file_id }) => file_id !== id)
            this.formFields['work_samples'].splice(index, 1)
            // const workSampleData = this.fieldsData.find(({ short_name }) => short_name === 'work_samples')
            // this.onBlur(workSampleData)
        },
        uploadFiles(files) {
            this.formFields['work_samples'].push(...files)
            // const workSampleData = this.fieldsData.find(({ short_name }) => short_name === 'work_samples')
            // this.onBlur(workSampleData)
        },
        async getWorkSamples() {
            try {
                const { data } = await Api.get('/api/file/applicant/list-work-samples')
                this.formFields['work_samples'] = data
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'error');
                console.error(err)
            }
        },
        async getData() {
            this.loading = true
            await this.getFields()
            await this.getWriterApplications()
            await this.getWorkSamples()
            this.loading = false
        }
    }
}
</script>

<style lang="scss">
    .wizard-general {
        &__title {
            font-size: 20px;
        }
        &__field {
            margin-bottom: 20px;
            display: grid;
            grid-template-columns: 4fr 8fr;
            gap: 20px;
            @media (max-width: 768px) {
                grid-template-columns: 1fr;
            }
            .form-select__input {
                padding-left: 10px;
            }
        }
        &__info {
            position: relative;
            display: grid;
            gap: 8px;
            align-self: start;
            &-title {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 5px;
                min-height: 24px;
            }
            &-description {
                font-size: 14px;
                line-height: 1.4;
            }
            &-field {
                position: relative;
            }
            &-label {
                &-saved {
                    position: absolute;
                    bottom: 5px;
                    right: 10px;
                    font-weight: 500;
                    font-size: 14px;
                    color: #1FB119;
                    span.mdi {
                        position: relative;
                        top: -2px;
                    }
                }
                &-public {
                    padding: 5px 7px;
                    border-radius: 4px;
                    background: #D1F2D0;
                    color: #1FB119;
                    font-weight: 500;
                    font-size: 12px;
                    cursor: pointer;
                    &::before {
                        max-width: 150px;
                        z-index: 1;
                        // @media (max-width: 576px) {
                        //     left: -20px;
                        // }
                    }
                    // @media (max-width: 1240px) {
                    //     right: 15px;
                    //     @media (max-width: 576px) {
                    //         right: 5px;
                    //     }
                    // }
                }
            }
        }
    }
</style>
