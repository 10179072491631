<template>
    <div class="work-samples">
        <file-uploader
            :max-file-size="8"
            :type="5"
            type-is-required
            loading-url="/api/file/applicant/upload"
            @error="$emit('validate')"
            @success="success"
        />
        <attachments-table
            :uploaded-files="files"
            :show-owner="false"
            :show-type="false"
            delete-url="/api/file/applicant/delete"
            @onDelete="deleteFile"
        />
        <delete-file-modal
            :id="deletedFile.file_id"
            :ref="`delete-modal`"
            url="/api/file/applicant/delete"
            @success="confirmDelete"
        />
    </div>
</template>

<script>
import FileUploader from '@/components/FileUploader.vue';
// import Close from 'mdi-vue/CloseCircleOutline.vue'
// import Download from 'mdi-vue/DownloadCircleOutline.vue'
import DeleteFileModal from '@/components/modals/DeleteFileModal';
import AttachmentsTable from '@/components/AttachmentsTable.vue';

export default {
    name: 'WorkSamples',
    components: {
        FileUploader,
        // Close,
        DeleteFileModal,
        // Download,
        AttachmentsTable
    },
    props: {
        files: {
            type: Array
        },
        errors: {
            type: String
        },
        label: { type: String }
    },
    data() {
        return {
            deletedFile: {}
        }
    },
    computed: {
        validationError() {
            return this.errors ? this.errors : false;
        }
    },
    methods: {
        success(files) {
            this.$emit('upload', files);
        },
        deleteFile(file) {
            this.deletedFile = { ...file }
            this.$refs['delete-modal'].open();
        },
        confirmDelete() {
            this.$emit('delete', this.deletedFile.file_id);
        }
    }
}
</script>

<style lang="scss">
.work-samples {
    .file-uploader__box_upload {
        margin: 0;
    }
    .file-uploader__box_uploaded {
        margin: 0;
        padding: 0;
    }
    .attachments-table__cell_size {
        @media (max-width: 996px) {
            margin-left: 70px;
        }
    }
}

.btn-base.small-btn.btn-pencil {
    border: none;
    font-size: 14px;
    &:hover {
        color: $main-color;
        svg {
            color: $main-color;
        }
    }
    svg {
        margin-right: 10px;
        color: $font-color-main;
        transition: .2s;
    }
}

@media screen and (max-width : 768px){

    .work-samples {

        &__input {
            padding-left: 0;
        }
    }
}
</style>
